import auth0 from "auth0-js";
import crypto from "isomorphic-webcrypto";

import { CONFIG } from "./appConfig";

// Cryptographically-secure random function
export function randomNonce() {
  var charset =
    "0123456789ABCDEFGHIJKLMNOPQRSTUVXYZabcdefghijklmnopqrstuvwxyz-._~";
  let result = "";

  var bytes = new Uint8Array(16);
  crypto.getRandomValues(bytes);

  // Take the low 6 bits from each byte and choose a character from the LUT
  bytes.forEach(c => {
    result += charset[c & 63];
  });
  return result;
}

// Redirects to Auth0 Universal Login Page
export function authorize(redirectUri) {
  const nonce = randomNonce();
  localStorage.setItem("nonce", nonce);
  let webAuth = getWebAuth({
    nonce: nonce,
    redirectUri: CONFIG.AppServer + "/callback"
  });
  webAuth.authorize();
}

// Upon returning from Universal Login, this parses the auth information
// that was returned in the URL hash.
export function parseHash() {
  return new Promise((resolve, reject) => {
    const nonce = localStorage.getItem("nonce");
    let webAuth = getWebAuth({ nonce: nonce });
    webAuth.parseHash({}, function(err, authResult) {
      if (authResult && authResult.accessToken && authResult.idToken) {
        storeAuth(authResult).then(() => {
          resolve();
        });
      } else {
        reject(err);
      }
    });
  });
}

// Creates an Auth0.js object
function getWebAuth(extraSettings) {
  let defaultSettings = {
    clientID: CONFIG.Auth0ClientID,
    domain: CONFIG.Auth0Domain,
    responseType: "token id_token",
    audience: CONFIG.Auth0Audience,
    scope: "openid profile email"
  };
  return new auth0.WebAuth(Object.assign({}, defaultSettings, extraSettings));
}

export function isAuthenticated() {
  // Check whether the current time is past the
  // access token's expiry time
  let expiresAt = JSON.parse(localStorage.getItem("expires_at"));
  return new Date().getTime() < expiresAt;
}

export async function storeAuth(authResult) {
  let expiresAt = JSON.stringify(
    authResult.expiresIn * 1000 + new Date().getTime()
  );
  await localStorage.setItem("access_token", authResult.accessToken);
  await localStorage.setItem("id_token", authResult.idToken);
  await localStorage.setItem("expires_at", expiresAt);
}

export function logout() {
  // Remove application session
  localStorage.removeItem("access_token");
  localStorage.removeItem("id_token");
  localStorage.removeItem("expires_at");
  localStorage.removeItem("nonce");

  // Redirect to auth0 to remove its session
  let webAuth = getWebAuth();
  const logoutUrl = webAuth.client.buildLogoutUrl({
    returnTo: CONFIG.AppServer + "/authorize",
    clientID: CONFIG.Auth0ClientID
  });
  window.location.replace(logoutUrl);
}

export function hasHash() {
  return /access_token|id_token|error/.test(window.location.hash);
}
