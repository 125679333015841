import React from "react";

import {
  List,
  Datagrid,
  TextField,
  NumberField,
  ReferenceInput,
  SelectInput,
  DateInput,
  Filter,
  Create,
  Edit,
  SimpleForm,
  TextInput,
  LongTextInput,
  NumberInput,
  required,
  CardActions,
  CreateButton,
  ExportButton,
  BooleanInput
} from "react-admin";
import { Route } from "react-router";
import { Drawer } from "@material-ui/core";

// Default timestamp to now
// Using a hack to get the right format
var transferDefaultValue = {
  timestamp: JSON.parse(JSON.stringify(new Date()))
};

export const TransferCreate = ({ onCancel, ...props }) => (
  <Create title=" " {...props}>
    <SimpleForm defaultValue={transferDefaultValue}>
      <TextInput
        source="timestamp"
        validate={required()}
        onChange={e => {
          transferDefaultValue.timestamp = e.target.value;
        }}
      />
      <LongTextInput source="remark" />
      <ReferenceInput label="From" source="from" reference="vessels">
        <SelectInput optionText={vesselRenderer} />
      </ReferenceInput>
      <ReferenceInput label="To" source="to" reference="vessels">
        <SelectInput optionText={vesselRenderer} />
      </ReferenceInput>
      <NumberInput source="amount" defaultValue={0} />
    </SimpleForm>
  </Create>
);

export const TransferEdit = ({ onCancel, ...props }) => (
  <Edit title=" " {...props}>
    <SimpleForm>
      <TextInput source="timestamp" validate={required()} />
      <LongTextInput source="remark" />
      <ReferenceInput label="From" source="from" reference="vessels">
        <SelectInput optionText={vesselRenderer} />
      </ReferenceInput>
      <ReferenceInput label="To" source="to" reference="vessels">
        <SelectInput optionText={vesselRenderer} />
      </ReferenceInput>
      <NumberInput source="amount" defaultValue={0} />
    </SimpleForm>
  </Edit>
);
const vesselRenderer = choice => {
  return `${choice.name} ${choice.description} (${choice.balance}L)`;
};
const TransferFilter = props => {
  // Set the default "to" value
  if (props && props.filterValues && props.filterValues.VesselID) {
    transferDefaultValue.to = props.filterValues.VesselID;
    transferDefaultValue.from = props.filterValues.VesselID;
  }

  return (
    <Filter {...props}>
      <ReferenceInput label="Vessel" source="VesselID" reference="vessels">
        <SelectInput optionText={vesselRenderer} />
      </ReferenceInput>
      <DateInput source="Since" label="Since" />
      <DateInput source="AsOf" label="Until" />
      <BooleanInput source="SinceLastEmpty" label="Since last empty" />
    </Filter>
  );
};

const TransferListActions = ({
  basePath,
  currentSort,
  displayedFilters,
  exporter,
  filters,
  filterValues,
  resource,
  selectedIds,
  showFilter,
  total
}) => (
  <CardActions>
    {filters &&
      React.cloneElement(filters, {
        resource,
        showFilter,
        displayedFilters,
        filterValues,
        context: "button"
      })}
    <CreateButton basePath={basePath} />
    <ExportButton
      disabled={total === 0}
      resource={resource}
      sort={currentSort}
      filter={filterValues}
      exporter={exporter}
    />
  </CardActions>
);

export class TransferList extends React.Component {
  render() {
    const { push, classes, ...props } = this.props;
    return (
      <React.Fragment>
        <List
          {...props}
          bulkActionButtons={false}
          pagination={null}
          filters={<TransferFilter />}
          filterDefaultValues={{ VesselID: 37, SinceLastEmpty: true }}
          actions={<TransferListActions />}
        >
          <Datagrid rowClick="edit">
            <NumberField source="id" sortable={false} />
            <TextField source="timestamp" sortable={false} />
            <TextField source="remark" sortable={false} />
            <TextField source="from_name" />
            <TextField source="to_name" />
            <NumberField source="amount" sortable={false} />
            <NumberField
              source="balance"
              sortable={false}
              style={{ fontWeight: "bold" }}
            />
          </Datagrid>
        </List>
        <Route path="/transfers/create">
          {({ match }) => (
            <Drawer open={!!match} anchor="right" onClose={this.handleClose}>
              <TransferCreate onCancel={this.handleClose} {...props} />
            </Drawer>
          )}
        </Route>
        <Route path="/transfers/:id">
          {({ match }) => {
            const isMatch =
              match && match.params && match.params.id !== "create";

            return (
              <Drawer
                open={!!isMatch}
                anchor="right"
                onClose={this.handleClose}
              >
                {isMatch ? (
                  <TransferEdit
                    id={isMatch ? match.params.id : null}
                    onCancel={this.handleClose}
                    {...props}
                  />
                ) : (
                  <div />
                )}
              </Drawer>
            );
          }}
        </Route>
      </React.Fragment>
    );
  }

  handleClose = () => {
    this.props.history.push("/transfers");
  };
}
