import React, { Component } from "react";
import { fetchJson } from "../react-admin/httpClient";

export class StockistsPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      cities: {}
    };
  }

  componentDidMount() {
    fetchJson("api/v1/customer/stockists").then(async resp => {
      this.setState({
        cities: resp.json
      });
    });
  }

  render() {
    const cities = Object.keys(this.state.cities).map(key => (
      <div>
        <b>{key || "No City"}</b>
        <br />
        {this.state.cities[key].map(cust => (
          <div>
            {cust.website ? <a href={cust.website}>{cust.name}</a> : cust.name}
          </div>
        ))}
        <br />
      </div>
    ));

    return (
      <div>
        <h1>Stockists</h1>
        <div>
          Customers who have ordered in the last 16 weeks
          <br />
          <br />
        </div>
        {cities}
      </div>
    );
  }
}
