import React, { Component } from "react";

import { load } from "./lib/appConfig";
import ReactAdminApp from "./react-admin";
import { hasHash, authorize, isAuthenticated } from "./lib/auth0";

// This App wrapper loads the app config and then
// manages routing between our two React-Admin apps
class App extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loaded: false,
      _isMounted: false
    };
  }

  componentDidMount() {
    this._isMounted = true;

    /* Load the app config */
    load().then(() => {
      if (this._isMounted) {
        // If we're not authenticated, don't bother loading react-admin yet
        if (isAuthenticated() || hasHash()) {
          this.setState({
            loaded: true
          });
        } else {
          authorize();
        }
      }
    });
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  render() {
    if (!this.state.loaded) {
      // FIXME: Display a loading spinner
      return null;
    }

    return <ReactAdminApp />;
  }
}

export default App;
