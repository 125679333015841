import React from "react";
import {
  List,
  Datagrid,
  TextField,
  BooleanField,
  DateField,
  Edit,
  SimpleForm,
  TextInput,
  NumberInput,
  BooleanInput,
  DisabledInput,
  Toolbar,
  SaveButton,
  CardActions,
  ExportButton,
  Button,
  ReferenceField,
  ReferenceInput,
  SelectInput,
} from "react-admin";
import StorefrontIcon from "@material-ui/icons/LocalGroceryStore";
import MapIcon from "@material-ui/icons/Map";
import { WebsiteField } from "../lib/fields";

const ListActions = ({
  bulkActions,
  basePath,
  currentSort,
  displayedFilters,
  exporter,
  filters,
  filterValues,
  onUnselectItems,
  resource,
  selectedIds,
  showFilter,
  total,
}) => (
  <CardActions>
    {bulkActions &&
      React.cloneElement(bulkActions, {
        basePath,
        filterValues,
        resource,
        selectedIds,
        onUnselectItems,
      })}
    {filters &&
      React.cloneElement(filters, {
        resource,
        showFilter,
        displayedFilters,
        filterValues,
        context: "button",
      })}
    <Button
      color="primary"
      onClick={() => {
        window.location.href = "/api/v0/customer/map";
      }}
      label="Map"
    >
      <MapIcon />
    </Button>
    <ExportButton
      label="Export CSV"
      disabled={total === 0}
      resource={resource}
      sort={currentSort}
      filter={filterValues}
      exporter={exporter}
    />
    <Button
      color="primary"
      onClick={() => {
        window.location.href = "/stockists";
      }}
      label="Stockists"
    >
      <StorefrontIcon />
    </Button>
  </CardActions>
);

const customerRowStyle = (record, index) => ({
  opacity: record.enabled ? "1.0" : "0.2",
});

export const CustomerList = (props) => (
  <List
    {...props}
    bulkActionButtons={false}
    pagination={null}
    actions={<ListActions />}
  >
    <Datagrid rowClick="edit" rowStyle={customerRowStyle}>
      <TextField source="id" sortable={false} />
      <TextField source="name" sortable={false} />
      <BooleanField source="enabled" sortable={false} />
      <ReferenceField
        source="sales_rep_id"
        reference="salesrep"
        label="Sales Rep"
      >
        <TextField source="name" />
      </ReferenceField>
      <DateField source="last_order" label="Last Order" sortable={false} />
      <BooleanField source="auto_charge" label="Auto-charge" sortable={false} />
      <WebsiteField source="website" sortable={false} />
      <TextField source="city" sortable={false} />
    </Datagrid>
  </List>
);

// Custom toolbar to remove the Delete button
const CustomerEditToolbar = (props) => (
  <Toolbar {...props}>
    <SaveButton label="Save" submitOnEnter={false} />
  </Toolbar>
);

export const CustomerEdit = (props) => (
  <Edit {...props}>
    <SimpleForm toolbar={<CustomerEditToolbar />}>
      <DisabledInput source="id" />
      <DisabledInput source="name" />
      <NumberInput source="license_num" />
      <TextInput source="license_type" />
      <BooleanInput source="enabled" />
      <BooleanInput label="Auto Charge" source="auto_charge" />
      <ReferenceInput
        label="Sales Rep"
        source="sales_rep_id"
        reference="salesrep"
      >
        <SelectInput source="name" />
      </ReferenceInput>
      <DisabledInput source="last_order" />
      <DisabledInput source="website" />
      <DisabledInput source="address" />
      <DisabledInput source="city" />
      <DisabledInput source="province" />
      <DisabledInput source="country" />
      <DisabledInput source="postal_code" />
      <DisabledInput source="latitude" />
      <DisabledInput source="longitude" />
    </SimpleForm>
  </Edit>
);
