import React, { Component } from "react";
import SaveIcon from "@material-ui/icons/Save";
import {
  List,
  Datagrid,
  TextField,
  NumberField,
  Button,
  Filter,
  ReferenceInput,
  SelectInput,
} from "react-admin";

import { fetchRegular } from "./httpClient";

export class LDBReportButton extends Component {
  handleClick = async () => {
    let resp = await fetchRegular("/api/v1/ldbreport/" + this.props.record.id);
    let blob = await resp.blob();

    var url = window.URL.createObjectURL(blob);
    var a = document.createElement("a");
    a.href = url;
    a.download = "ldbreport-" + this.props.record.id + ".csv";
    document.body.appendChild(a);
    a.click();
    a.remove();
  };

  render() {
    return (
      <Button label="LDB Report" onClick={this.handleClick}>
        <SaveIcon />
      </Button>
    );
  }
}

const SalesMonthFilter = (props) => (
  <Filter {...props}>
    <ReferenceInput
      label="Products"
      source="product_id"
      reference="products"
      filter={{ show: true }}
    >
      <SelectInput optionText="name" />
    </ReferenceInput>
    <SelectInput
      source="volume"
      choices={[
        { id: 0.5, name: "500mL Bottle" },
        { id: 0.75, name: "750mL Bottle" },
        { id: 0.355, name: "355mL Can" },
        { id: 0.473, name: "473mL Can" },
        { id: 19.5, name: "19.5L Keg" },
        { id: 30, name: "30L Keg" },
      ]}
    />
  </Filter>
);

const SalesMonthsListDatagrid = (props) => (
  <Datagrid {...props} rowClick={null}>
    <TextField source="id" sortable={false} />
    <NumberField source="promo_units" sortable={false} />
    <NumberField source="retail_units" sortable={false} />
    <NumberField source="hospitality_units" sortable={false} />
    <NumberField source="wholesale_units" sortable={false} />
    <NumberField
      source="total_litres"
      sortable={false}
      options={{ maximumFractionDigits: 0 }}
    />
    <NumberField
      source="shopify_retail_revenue"
      sortable={false}
      options={{ style: "currency", currency: "CAD" }}
    />
    <NumberField
      source="beervan_retail_revenue"
      sortable={false}
      options={{ style: "currency", currency: "CAD" }}
    />
    <NumberField
      source="other_retail_revenue"
      sortable={false}
      options={{ style: "currency", currency: "CAD" }}
    />
    <NumberField
      source="hospitality_revenue"
      sortable={false}
      options={{ style: "currency", currency: "CAD" }}
    />
    <NumberField
      source="wholesale_revenue"
      sortable={false}
      options={{ style: "currency", currency: "CAD" }}
    />
    <NumberField
      source="total_revenue"
      sortable={false}
      options={{ style: "currency", currency: "CAD" }}
    />
    {props.filterValues.hasOwnProperty("product_id") ? null : (
      <LDBReportButton />
    )}
  </Datagrid>
);

export const SalesMonthList = (props) => (
  <List
    {...props}
    bulkActionButtons={false}
    pagination={null}
    filters={<SalesMonthFilter />}
  >
    <SalesMonthsListDatagrid />
  </List>
);
