import { fetchUtils } from "react-admin";

export function fetchJson(url, options = {}) {
  if (!options.headers) {
    options.headers = new Headers({ Accept: "application/json" });
  }
  const token = localStorage.getItem("access_token");
  options.headers.set("Authorization", `Bearer ${token}`);
  return fetchUtils.fetchJson(url, options);
}

export function fetchRegular(url, options = {}) {
  if (!options.headers) {
    options.headers = new Headers({ Accept: "application/json" });
  }
  const token = localStorage.getItem("access_token");
  options.headers.set("Authorization", `Bearer ${token}`);
  return fetch(url, options);
}
