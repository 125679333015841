import React from "react";

import {
  List,
  Datagrid,
  TextField,
  NumberField,
  Create,
  Edit,
  SimpleForm,
  TextInput,
  Toolbar,
  SaveButton,
} from "react-admin";

export const SalesRepList = (props) => (
  <List {...props} bulkActionButtons={false} pagination={null}>
    <Datagrid rowClick="edit">
      <NumberField source="id" sortable={false} />
      <TextField source="name" sortable={false} />
    </Datagrid>
  </List>
);

export const SalesRepCreate = (props) => (
  <Create {...props}>
    <SimpleForm>
      <TextInput source="name" />
    </SimpleForm>
  </Create>
);

// Custom toolbar to remove the Delete button
const SalesRepEditToolbar = (props) => (
  <Toolbar {...props}>
    <SaveButton label="Save" submitOnEnter={false} />
  </Toolbar>
);

export const SalesRepEdit = (props) => (
  <Edit {...props}>
    <SimpleForm toolbar={<SalesRepEditToolbar />}>
      <TextInput source="name" />
    </SimpleForm>
  </Edit>
);
