import React, { Component } from "react";
import { Button } from "react-admin";
import CircularProgress from "@material-ui/core/CircularProgress";
import { fetchJson, fetchRegular } from "../react-admin/httpClient";

export class QBOPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      output: "",
    };
    this.connectToQBO = this.connectToQBO.bind(this);
    this.syncAll = this.syncAll.bind(this);
  }

  componentDidMount() {
    fetchJson("/api/v1/qbo").then(async (resp) => {
      this.setState(resp.json);
    });
  }

  connectToQBO() {
    fetchJson("/api/v1/qbo/connect").then((resp) => {
      window.location.href = resp.json.url;
    });
  }

  async syncAll() {
    this.setState({ syncing: true });
    fetchRegular("/api/v1/qbo/sync")
      .then(async (resp) => {
        let reader = (await resp.body).getReader();
        const utf8Decoder = new TextDecoder("utf-8");
        let done = false;
        do {
          const r = await reader.read();
          const value = r.value ? utf8Decoder.decode(r.value) : "";
          done = r.done;

          this.setState({
            output: this.state.output + value,
          });
        } while (!done);
        this.setState({
          syncing: false,
        });
      })
      .catch((err) => {
        this.setState({
          syncing: false,
          error: err,
        });
      });
  }

  render() {
    const status = (
      <div style={{ paddingBottom: "2em" }}>
        <h2>QBO Sync Status</h2>
        <div>QBO Connected: {this.state.logged_in ? "Yes" : "No"}</div>
        <div>Latest invoice fetched: {this.state.last_invoice}</div>
        <div>Time since last change: {this.state.last_change}</div>
      </div>
    );

    const button = this.state.logged_in ? (
      <div>
        <div>
          <span>
            <Button
              variant="contained"
              size="large"
              label="Sync All"
              onClick={this.syncAll}
              disabled={this.state.syncing}
            >
              {this.state.syncing ? <CircularProgress size={12} /> : null}
            </Button>
          </span>
        </div>
        <div>
          <p style={{ whiteSpace: "pre" }}>{this.state.output}</p>
        </div>
      </div>
    ) : (
      <span>
        <Button
          variant="contained"
          size="large"
          label="Connect to QBO"
          onClick={this.connectToQBO}
        />
      </span>
    );

    return (
      <div>
        {status}
        {button}
      </div>
    );
  }
}
