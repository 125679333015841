import React from "react";
import OpenIcon from "@material-ui/icons/OpenInNew";

export function WebsiteField({ record, source }) {
  if (!record[source]) return null;

  return (
    <a href={record[source]} target="_blank" rel="noopener noreferrer">
      <OpenIcon />
    </a>
  );
}
