import React from "react";

import {
  List,
  Datagrid,
  TextField,
  BooleanField,
  NumberField,
  Edit,
  SimpleForm,
  TextInput,
  BooleanInput,
  SelectInput,
  NumberInput,
  DisabledInput,
  Toolbar,
  SaveButton,
  Filter
} from "react-admin";

const ProductFilter = props => (
  <Filter {...props}>
    <SelectInput
      source="volume"
      choices={[
        { id: 0.5, name: "500mL Bottle" },
        { id: 0.75, name: "750mL Bottle" },
        { id: 0.355, name: "355mL Can" },
        { id: 0.473, name: "473mL Can" },
        { id: 19.5, name: "19.5L Keg" },
        { id: 30, name: "30L Keg" }
      ]}
    />
    <BooleanInput source="show" label="Enabled" />
  </Filter>
);

const productRowStyle = (record, index) => ({
  opacity: record.show ? "1.0" : "0.2",
});

export const ProductList = props => (
  <List
    {...props}
    bulkActionButtons={false}
    pagination={null}
    filters={<ProductFilter />}
  >
    <Datagrid rowClick="edit" rowStyle={productRowStyle}>
      <NumberField source="id" sortable={false} />
      <TextField source="name" sortable={false} />
      <NumberField source="volume" sortable={false} />
      <TextField source="sku" sortable={false} />
      <BooleanField source="show" sortable={false} label="Enabled" />
      <NumberField
        source="ldb_wholesale"
        sortable={false}
        options={{ style: "currency", currency: "CAD" }}
      />
      <NumberField
        source="ldb_hospitality"
        sortable={false}
        options={{ style: "currency", currency: "CAD" }}
      />
      <NumberField
        source="bottle_deposit"
        sortable={false}
        options={{ style: "currency", currency: "CAD" }}
      />
      <NumberField
        source="wholesale"
        sortable={false}
        options={{ style: "currency", currency: "CAD" }}
      />
      <NumberField
        source="hospitality"
        sortable={false}
        options={{ style: "currency", currency: "CAD" }}
      />
      <NumberField
        source="retail"
        sortable={false}
        options={{ style: "currency", currency: "CAD" }}
      />
    </Datagrid>
  </List>
);

// Custom toolbar to remove the Delete button
const CustomerEditToolbar = props => (
  <Toolbar {...props}>
    <SaveButton label="Save" submitOnEnter={false} />
  </Toolbar>
);

export const ProductEdit = props => (
  <Edit {...props}>
    <SimpleForm toolbar={<CustomerEditToolbar />}>
      <DisabledInput source="id" />
      <TextInput source="name" />
      <NumberInput source="volume" />
      <TextInput source="sku" />
      <BooleanInput source="show" label="Enabled" />
      <NumberInput source="ldb_wholesale" />
      <NumberInput source="ldb_hospitality" />
      <NumberInput source="bottle_deposit" />
      <NumberInput source="wholesale" />
      <NumberInput source="hospitality" />
      <NumberInput source="retail" />
    </SimpleForm>
  </Edit>
);
