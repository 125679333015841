import React, { Component } from "react";

import {
  List,
  Datagrid,
  TextField,
  NumberField,
  BooleanInput,
  DateInput,
  Filter,
  Create,
  Edit,
  RadioButtonGroupInput,
  SimpleForm,
  TextInput,
  EditButton,
  Button,
} from "react-admin";
import ListIcon from "@material-ui/icons/List";
import { Link } from "react-router-dom";

class TransfersButton extends Component {
  render() {
    const f = JSON.stringify({
      VesselID: this.props.record.id,
      SinceLastEmpty: true,
    });
    const href = "/transfers?filter=" + f;

    return (
      <Button component={Link} label="Transfers" to={href}>
        <ListIcon />
      </Button>
    );
  }
}

const VesselListAside = ({ ids, data }) => {
  const total = ids.reduce((acc, i) => {
    const cur = data[i];
    if (cur.type !== "tank") return acc;
    return acc + cur.balance;
  }, 0);
  return (
    <div style={{ margin: "1em" }}>
      <p>
        <b>Tank Total</b>
        <br />
        {total} L
      </p>
    </div>
  );
};

const VesselFilter = (props) => (
  <Filter {...props}>
    <BooleanInput source="inUse" label="In use" />
    <DateInput source="Since" label="Change Since" />
    <DateInput source="AsOf" label="At end of" />
  </Filter>
);

const vesselRowStyle = (record, index) => ({
  opacity: record.balance ? "1.0" : "0.3",
});

export const VesselList = (props) => (
  <List
    {...props}
    bulkActionButtons={false}
    pagination={null}
    filters={<VesselFilter />}
    filterDefaultValues={{ inUse: true }}
    aside={<VesselListAside />}
  >
    <Datagrid rowStyle={vesselRowStyle}>
      <NumberField source="id" sortable={false} />
      <TextField source="type" sortable={false} />
      <TextField source="name" sortable={false} />
      <TextField source="description" sortable={false} />
      <NumberField source="balance" sortable={false} />
      <EditButton />
      <TransfersButton />
    </Datagrid>
  </List>
);

export const VesselCreate = (props) => (
  <Create {...props}>
    <SimpleForm>
      <RadioButtonGroupInput
        source="type"
        choices={[
          { id: "tank", name: "Tank" },
          { id: "input", name: "Input" },
          { id: "output", name: "Output" },
        ]}
      />
      <TextInput source="name" />
      <TextInput source="description" />
    </SimpleForm>
  </Create>
);

export const VesselEdit = (props) => (
  <Edit {...props}>
    <SimpleForm>
      <RadioButtonGroupInput
        source="type"
        choices={[
          { id: "tank", name: "Tank" },
          { id: "input", name: "Input" },
          { id: "output", name: "Output" },
        ]}
      />
      <TextInput source="name" />
      <TextInput source="description" />
    </SimpleForm>
  </Edit>
);
