import { Component } from "react";

import { hasHash, parseHash, authorize } from "../lib/auth0";

// LoginPage is a blank page to be given to react-admin to use as its "loginPage".
// Since we do auth outside auth0, we need to give it a login page that does nothing.
export class LoginPage extends Component {
  render() {
    return null;
  }
}

// AuthorizePage is our "real" login page.
// It redirects to Auth0 to do the login flow.
export class AuthorizePage extends Component {
  componentDidMount() {
    authorize();
  }

  render() {
    return null;
  }
}

// LoginCallbackPage is where the user is redirected after a successful login at Auth0.
// It includes the hash from which we can get the user's accessToken and store it in localStorage.
export class LoginCallbackPage extends Component {
  componentDidMount() {
    console.log(this.props);
    if (hasHash()) {
      parseHash()
        .then(async () => {
          // then redirect to home page
          // with a full page refresh so that the new permissions can re-render
          window.location.href = "/";
        })
        .catch(e => {
          console.log("Hash did not parse: ", e);
        });
    } else {
      console.log("Hash not found");
    }
  }

  render() {
    return null;
  }
}
