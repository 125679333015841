import React from "react";

import {
  List,
  Datagrid,
  TextField,
  SelectInput,
  ReferenceInput,
  Filter,
  downloadCSV,
  Edit,
  SimpleForm,
  DisabledInput,
  TextInput,
} from "react-admin";
import { unparse as convertToCSV } from "papaparse/papaparse.min";

const InvoiceFilter = (props) => (
  <Filter {...props}>
    <ReferenceInput label="Sales Rep" source="SalesRepID" reference="salesrep">
      <SelectInput source="name" />
    </ReferenceInput>
    <SelectInput
      source="Year"
      choices={[
        { id: 2015, name: 2015 },
        { id: 2016, name: 2016 },
        { id: 2017, name: 2017 },
        { id: 2018, name: 2018 },
        { id: 2019, name: 2019 },
        { id: 2020, name: 2020 },
        { id: 2021, name: 2021 },
        { id: 2022, name: 2022 },
        { id: 2023, name: 2023 },
        { id: 2024, name: 2024 },
        { id: 2025, name: 2025 },
        { id: 2026, name: 2026 },
        { id: 2027, name: 2027 },
        { id: 2028, name: 2028 },
        { id: 2029, name: 2029 },
      ]}
    />
    <SelectInput
      source="Month"
      choices={[
        { id: 1, name: 1 },
        { id: 2, name: 2 },
        { id: 3, name: 3 },
        { id: 4, name: 4 },
        { id: 5, name: 5 },
        { id: 6, name: 6 },
        { id: 7, name: 7 },
        { id: 8, name: 8 },
        { id: 9, name: 9 },
        { id: 10, name: 10 },
        { id: 11, name: 11 },
        { id: 12, name: 12 },
      ]}
    />
  </Filter>
);

function genTotal(record) {
  const s =
    record.data &&
    record.data.Line &&
    record.data.Line.map((l) =>
      l.DetailType === "SalesItemLineDetail" ? l.Amount : 0
    )
      .reduce((a, b) => a + b, 0)
      .toFixed(2);

  if (record.type === "Credit Memo") return -s;
  return s;
}

const TotalField = (props) => {
  return genTotal(props.record);
};

const exporter = (invoices) => {
  const invoicesForExport = invoices.map((inv) => {
    const { Line, ...invForExport } = inv;
    invForExport.total = genTotal(inv);
    return invForExport;
  });

  const csv = convertToCSV({
    data: invoicesForExport,
    fields: ["doc_number", "customer_name", "txn_date", "total"],
  });
  downloadCSV(csv, "invoices"); // download as 'invoices.csv` file
};

export const InvoiceList = (props) => (
  <List
    {...props}
    bulkActionButtons={false}
    pagination={null}
    filters={<InvoiceFilter />}
    filterDefaultValues={{
      Month: new Date().getMonth() + 1,
      Year: new Date().getYear() + 1900,
    }}
    exporter={exporter}
  >
    <Datagrid rowClick="edit">
      <TextField source="doc_number" label="Invoice" sortable={false} />
      <TextField source="customer_name" label="Customer" sortable={false} />
      <TextField source="txn_date" label="Date" sortable={false} />
      <TotalField label="Total" />
      <TextField source="data.Balance" label="Balance" sortable={false} />
      <TextField source="fulfilled" label="Fulfilled" sortable={false} />
    </Datagrid>
  </List>
);

export const InvoiceEdit = (props) => (
  <Edit {...props}>
    <SimpleForm>
      <DisabledInput source="doc_number" label="Invoice" />
      <DisabledInput source="customer_name" label="Customer" />
      <DisabledInput source="txn_date" label="Date" />
      <TotalField label="Total" />
      <TextInput source="fulfilled" />
    </SimpleForm>
  </Edit>
);
