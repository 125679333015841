import { AUTH_LOGIN, AUTH_LOGOUT, AUTH_ERROR } from "react-admin";
import { logout } from "../lib/auth0.js";

export default (type, params) => {
  // Login is handled outside of react-admin so we don't do anything here
  if (type === AUTH_LOGIN) {
    return Promise.resolve();
  }

  // AUTH_LOGOUT happens when the user selects the logout menu option.
  // We call our own logout() which in turn redirects via Auth0
  if (type === AUTH_LOGOUT) {
    logout();
    return Promise.resolve();
  }

  // AUTH_ERROR is called by the dataProvider when the API rejects our request
  // We intercept api auth problems so we can logout() to trigger a new login session
  if (type === AUTH_ERROR) {
    const status = params.status;
    if (status === 401) {
      logout();
      return Promise.reject();
    }
    return Promise.resolve();
  }

  return Promise.resolve();
};
